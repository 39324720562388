.mailer-signup__container{
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid black;
  width: 450px;
  /* border-radius: 2%; */
  /* box-shadow: 2px 5px 3px 0 rgb(0 0 0 / 50%); */
}

.mailer-signup__form{
  display: flex;
}
.mailer-signup__email-input{
  width: 200px;
}
.mailer-signup__submit-btn{
 background-color: black;
  color: white;
  width: 300px;
  padding: 7px;
  cursor: pointer;
  font-size: medium;
}

@media (max-width: 800px){
  .mailer-signup__container{
    display: flex;
    flex-direction: column;
    text-align: center;
    border: none;
    width: 300px;
  }
  .mailer-signup__email-input{
    width: 293px;
    height: 25px;
  }
  .mailer-signup__form{
    display: flex;
    flex-direction: column;
  }
}