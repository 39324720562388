.contact__container{
  text-align: center;
  min-height: 60vh;
  display: flex;
flex-direction: column;  
justify-content: space-around;
}
.contact__banner-holder{
  width: 100%;
  display: flex;
  justify-content: center;
}
.contact__image{
  margin-right: 5px;
}

@media (max-width: 800px){
  .contact__banner-holder{
    flex-direction: column;
    align-items: center;
  }
  .contact__image{
    margin-right: 0px;
    margin-bottom: 10px;
  }
  
}