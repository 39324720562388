a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

a:active {
  text-decoration: none;
  color: white;
}

.footer__container{
  height: 200px;
  width: 100%;
  background-color: black;
  color:white;
  margin-top: 50px;
}
.footer__links-holder{
  display: flex;
  /* width: 250px; */
  width: 100%;
  justify-content: space-around;
}
.footer__bottom-text{
  width: 100%;
  text-align: center;
  font-size: x-small;
}
.footer__neck-img-holder{
 position: relative;
 top: 50px;
}
.footer__neck-img{
  height: 100px;
}

@media (max-width: 800px){
  .footer__bottom-text{
    width: 95%;
    font-size: 7px;
    padding-left: 2%;
  }
  
}