a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.nav__container{
  display: flex;
  width: 100%;
  justify-content: center;
  /* background-color: white; */
 
  border-radius: 52%;
}
.nav__tab-holder{
  display: flex;
  justify-content: space-around;
  width: 50%;
}
.nav__container p{
  padding: 10px
}
.nav__container p:hover{
  border: 1px solid black;
  padding: 9px;
  color:white;
  background-color: brown;
  cursor: pointer;
  box-shadow: 12px 12px 80px rgb(0 0 0 / 10%);
}
.nav__productions-dropdown-open{
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #F4F0DB;
  border: 1px solid black;
  /* background-color: white; */
}
.nav__productions-dropdown-open a:link{
  text-decoration:none;
  color: black;
}
.nav__productions-dropdown-open a:visited{
  text-decoration:none;
  color: black;
}
.nav__productions-dropdown-open a:active{
  text-decoration:none;
  color: black;
}
.nav__productions-dropdown-closed{
  display: none;
}
.nav-chosen{
  color: red

}
.nav-unchosen{
  color: black;
}
.test:hover{
  border: 1px solid black;
  padding: 4px

}