.header-container {
  /* position: sticky;
  top: 0px; */
  /* background-color: white; */
  /* background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%); */
  /* border-radius: 2%; */
  /* background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply; */
}
.nrt-title-container {
  width: 100%;
  text-align: center;
}

.nrt-title {
  color: black;
  /* background-color: white; */
  
  
  padding: 10px;
  /* font-family: "Cormorant SC", serif; */
  /* font-family: 'Codystar', cursive; */
  font-family: 'Josefin Slab', serif;
  font-size: 50px;
}
.nav-holder {
}

@media (max-height: 816px) {
  .nav-holder {
    position: relative;
    /* top: -15px; */
  }
}

@media (max-width: 677px) {
  .nrt-title {
    font-size: 1.5em;
  }
}

@media (max-height: 651px) {
  .nav-holder {
    position: relative;
    top: 20px;
  }
}

@media (max-width: 481px) {
  .nrt-title {
    font-size: 1.5em;
    position: relative;
    /* top: -14px; */
  }
}
@media (max-height: 350px) {
  .nav-holder {
    position: relative;
    top: 10px;
  }
}
