.outreach-container {
  /* min-height: 100vh; */
  /* margin-bottom: 100px; */
  min-height: 100vh;
  padding-bottom: 20px;
}
.kids-opera-banner-img-container {
  padding-top: 2%;
  width: 100%;
  text-align: center;
}
.kids-opera-banner-img {
  max-width: 100%
}
.outreach-text-container {
  padding-left: 10%;
  padding-right: 10%;
}
