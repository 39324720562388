.layout-container{
display: flex;
flex-direction: column;
align-items: center; 

}

.layout-holder{
  width: 100%;
  max-width: 1200px;
  background-color: #F4F0DB;
  min-height: 100vh;
}

.footer__container{
  
}